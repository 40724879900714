<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text py-0">
        <v-col cols="8" class="p-0"
          >Nueva importación de relación afiliado - empresa</v-col
        >
      </v-card-title>
      <v-card-text>
        <!-- Proceso de importación -->
        <v-row>
          <v-col
            col="12"
            md="12"
            class="pt-0 pb-2 pl-2"
            align="start"
            align-self="center"
          >
            <v-btn-toggle dense v-model="text" color="primary" group>
              <v-btn value="ARCHIVO TXT" @click="(isTxt = true), (file = null)">
                Archivo TXT
              </v-btn>
              <v-btn @click="(isTxt = false), (fileTxt = null)">
                Archivo EXCEL
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row cols="12" class="py-0">
          <v-col v-if="!isTxt" cols="7">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              id="fileUpload"
              type="file"
              dense
              label="Archivo excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col v-else cols="7">
            <v-file-input
              ref="fileTxt"
              v-model="fileTxt"
              :disabled="loading || loadingProcess"
              :loading="loading"
              dense
              label="Archivo txt"
              placeholder="Seleccionar el archivo Txt"
              accept=".txt"
              outlined
              :show-size="1000"
              @change="readTxtFormatFile"
              value=""
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2" class="py-0">
            <v-btn
              color="primary"
              class="to-right"
              outlined
              :disabled="isTxt"
              @click="openModalSeeFormatoArchivo()"
            >
              Ver formato
            </v-btn></v-col
          >
          <v-btn class="mr-4" outlined @click="closeModalExcel()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!enableProcess || (!file && !fileTxt)"
            :loading="loadingProcess"
            @click="saveProcesoImportacion(fileDataTranslated, true)"
          >
            Procesar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      :max-width="procesoFinalizado ? '65%' : '85%'"
      persistent
    >
      <v-card :loading="loadingProcess">
        <v-container>
          <v-card-title class="py-1">
            <v-col v-if="!procesoFinalizado" cols="12" class="py-0 pl-0">
              <span class="primary--text font-weight-medium"
                >Resumen archivos procesados</span
              >
            </v-col>
          </v-card-title>
          <v-card-text v-if="!procesoFinalizado">
            <v-data-table
              style="max-width: 1900px"
              :headers="isTxt ? headersRegistros : headersRegistrosExcel"
              :items="registrosToSee"
              :search="search"
              dense
              show-expand
              item-key="idRegistro"
              :items-per-page="15"
              class="elevation-2"
              :expanded.sync="expanded"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end pr-11">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="toggleExpandAll"
                            size="26"
                            v-on="on"
                            v-bind="attrs"
                          >
                            {{ allExpanded ? chevronUpIcon : chevronDownIcon }}
                          </v-icon>
                        </template>
                        <span>{{allExpanded ? 'Contraer todo' : 'Expandir todo' }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template
                v-slot:[`item.data-table-expand`]="{ item, isExpanded }"
              >
                <template v-if="item.errores">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    size="20"
                    style="cursor: pointer"
                  >
                    {{ isExpanded ? chevronUpIcon : chevronDownIcon }}
                  </v-icon>
                </template>
                <template v-else>
                  <v-icon small color="primary">
                    {{ checkIcon }}
                  </v-icon>
                </template>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td style="color: red" :colspan="headers.length">
                  <strong style="font-size: 12px">Errores:</strong>
                  {{ item.errores }}
                </td>
              </template>
              <template v-if="!isTxt" v-slot:[`item.sueldo`]="{ item }">
                <span>{{
                  item.sueldo == null ||
                  item.sueldo == undefined ||
                  isNaN(item.sueldo) || 
                  item.sueldo == ""
                    ? "0,00"
                    : helpers.floatToMoneyString(
                        parseFloat(item.sueldo),
                        "",
                        true
                      )
                }}</span>
              </template>
              <template v-if="!isTxt" v-slot:[`item.aporte`]="{ item }">
                <span>{{
                  item.aporte == null ||
                  item.aporte == undefined ||
                  isNaN(item.aporte) ||
                  item.aporte == ""
                    ? "0,00"
                    : helpers.floatToMoneyString(
                        parseFloat(item.aporte),
                        "",
                        true
                      )
                }}</span>
              </template>
              <template v-if="!isTxt" v-slot:[`item.contribucion`]="{ item }">
                <span>{{
                  item.contribucion == null ||
                  item.contribucion == undefined ||
                  isNaN(item.contribucion) || 
                  item.contribucion == ""
                    ? "0,00"
                    : helpers.floatToMoneyString(
                        parseFloat(item.contribucion),
                        "",
                        true
                      )
                }}</span>
              </template>
              <template v-slot:[`body.append`]="{ headers }">
                <tr>
                  <td v-for="(header, i) in headers" :key="i">
                    <div
                      v-if="header.value == 'cuil'"
                      style="text-align: left; font-size: 15px"
                    >
                      Total ok
                    </div>
                    <div
                      :style="{ 'text-align': 'center' }"
                      v-if="header.value == 'data-table-expand'"
                    >
                      <strong> {{ cantRegistrosOk }}</strong>
                    </div>
                  </td>
                </tr>
                <!-- //errores -->
                <tr>
                  <td v-for="(header, i) in headers" :key="i">
                    <div
                      v-if="header.value == 'cuil'"
                      style="text-align: left; font-size: 15px"
                    >
                      Total errores
                    </div>
                    <div
                      style="text-align: center"
                      v-if="header.value == 'data-table-expand'"
                    >
                      <strong> {{ cantRegistrosErrores }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-title class="py-0">
            <span v-if="procesoFinalizado" class="pl-1 primary--text">
              Procesado con éxito! <br />
              Se insertaron {{ cantidadBenefEmpresa }} registros relación
              empresa - afiliado.<br />
              Se insertaron {{ cantidadEmpresas }} registros de empresas.<br />
              {{
                !isTxt
                  ? "Se insertaron " +
                    cantidadSucursales +
                    " registros de sucursales"
                  : ""
              }}
              <br />
            </span>
            <span v-if="!procesoFinalizado" class="pl-1 py-0 primary--text">
              {{
                cantRegistrosOk > 0
                  ? "¿Desea importar los registros correctos?"
                  : "Verifique el archivo y vuelva a intentar."
              }}
              <br />
            </span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="
                (modalExportarExcel = false),
                  (registrosErrores = []),
                  (registrosOk = []), 
                  (allExpanded = false)
              "
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="to-right"
              text
              :disabled="cantRegistrosOk < 1"
              :loading="loadingProcess"
              @click="navigateProcesarArchivo()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :maxWidth="'65%'"
      :formatoTitle="formatoTitle"
      :headers="headers"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>
  
<script>
import { mapActions } from "vuex";
import helpers from "@/utils/helpers.js";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";

export default {
  name: "NuevoProcesoImportacionAfiliadoEmpresa",
  components: { FormatoArchivoDialog },
  data() {
    return {
      loading: false,
      searchIcon: enums.icons.SEARCH,
      comprobantesAGenerar: [],
      excelData: [],
      search: "",
      helpers: helpers,
      modalExportarExcel: false,
      registrosErrores: [],
      registrosOk: [],
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      checkIcon: enums.icons.CHECK_OUTLINE,
      archivoEnProceso: false,
      file: null,
      text: "ARCHIVO TXT",
      procesoFinalizado: false,
      fileName: null,
      formatoTitle: "Formato de archivo",
      headers: [],
      expanded: [],
      datosFormatoArchivo: [],
      enableProcess: false,
      loadingProcess: false,
      isTxt: true,
      fileTxt: null,
      showSeeFormatModal: false,
      cantidadBenefEmpresa: 0,
      cantidadEmpresas: 0,
      cantRegistrosOk: 0,
      cantidadSucursales: 0,
      cantRegistrosErrores: 0,
      content: null,
      fileDataTranslated: [],
      registrosToSee: [],
      headersRegistros: [
        {
          text: "CUIL",
          sortable: false,
          align: "left",
          value: "cuil",
        },
        {
          text: "Afiliado",
          sortable: false,
          align: "left",
          value: "nombreAfiliado",
        },
        {
          text: "CUIT empresa",
          sortable: false,
          align: "left",
          value: "cuitEmpresa",
        },
        {
          text: "Nombre empresa",
          sortable: false,
          align: "left",
          value: "nombreEmpresa",
        },
        {
          text: "Vigencia desde",
          sortable: false,
          align: "left",
          value: "fecha",
        },
        {
          text: "Vigencia hasta",
          sortable: false,
          align: "left",
          value: "fechaBaja",
        },
        {
          text: "Observaciones",
          value: "data-table-expand",
          sortable: false,
          align: "center",
        },
      ],
      headersRegistrosExcel: [
        {
          text: "CUIL afiliado",
          sortable: false,
          align: "left",
          value: "cuil",
        },
        {
          text: "Tipo documento",
          sortable: false,
          align: "left",
          value: "tipoDoc",
        },
        {
          text: "Número documento",
          sortable: false,
          align: "left",
          value: "nroDoc",
        },
        {
          text: "CUIT empresa",
          sortable: false,
          align: "left",
          value: "cuitEmpresa",
        },
        {
          text: "Sueldo",
          sortable: false,
          align: "left",
          value: "sueldo",
        },
        {
          text: "Aporte",
          sortable: false,
          align: "left",
          value: "aporte",
        },
        {
          text: "Contribución",
          sortable: false,
          align: "left",
          value: "contribucion",
        },
        {
          text: "Vigencia desde",
          sortable: false,
          align: "left",
          value: "fecha",
        },
        {
          text: "Vigencia hasta",
          sortable: false,
          align: "left",
          value: "fechaBaja",
        },
        {
          text: "Observaciones",
          value: "data-table-expand",
          sortable: false,
          align: "center",
        },
      ],
      dataToExport: {
        formatoName: "Proceso importación relación afiliado - empresa modelo",
        data: [
          {
            ["CUIL afiliado"]: "201111111119",
            ["Tipo documento"]: "DNI",
            ["N° de documento"]: "11111111",
            ["CUIT empresa"]: "30111111119",
            ["Nombre empresa"]: "CORDOBA",
            ["Sueldo"]: "150000",
            ["Aporte"]: "4500",
            ["Contribucion"]: "7000",
            ["Vigencia desde"]: "01/01/2025",
            ["Vigencia hasta"]: "01/01/2026",
            ["Sucursal"]: "CORDOBA",
            ["Empresa por defecto"]: "SI",
          },
        ],
      },
      allExpanded: false,
    };
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getFormatoProcesoImportacionAfiliadoEmpresa:
        "procesosAfiliado/getFormatoProcesoImportacionAfiliadoEmpresa",
      saveProcesoImportacionAfiliadoEmpresa:
        "procesosAfiliado/saveProcesoImportacionAfiliadoEmpresa",
    }),
    async importExcel() {
      if (this.file) {
        try {
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.loading = false;
            this.fileName = this.file.name;
            this.fileDataTranslated = [];
            const expectedHeaders = [
              "CUIL afiliado",
              "Tipo documento",
              "N° de documento",
              "CUIT empresa",
              "Sueldo",
              "Aporte",
              "Contribucion",
              "Vigencia desde",
              "Vigencia hasta",
              "Sucursal",
              "Empresa por defecto",
            ];
            const validHeaders = await helpersExcel.validarCabecerasExcel(
              this.file,
              expectedHeaders
            );
            if (!validHeaders) {
              this.loading = false;
              this.enableProcess = false;
              this.archivoEnProceso = false;
              this.$refs["file"].reset();
              return;
            }
            this.excelData[0].filas.forEach((x) => {
              this.fileDataTranslated.push({
                cuil: x["CUIL afiliado"]?.toString()?.trim() ?? "",
                tipoDoc: x["Tipo documento"]?.toString()?.trim() ?? "",
                nroDoc: x["N° de documento"]?.toString()?.trim() ?? "",
                cuitEmpresa: x["CUIT empresa"]?.toString()?.trim() ?? "",
                nombreEmpresa: x["Nombre empresa"]?.toString()?.trim() ?? "",
                sueldo: x["Sueldo"]?.toString()?.trim() ?? "",
                aporte: x["Aporte"]?.toString()?.trim() ?? "",
                contribucion: x["Contribucion"]?.toString()?.trim() ?? "",
                fecha: helpersExcel.translateDate(x["Vigencia desde"]) ?? "",
                fechaBaja: helpersExcel.translateDate(x["Vigencia hasta"]) ?? "",
                sucursal: x["Sucursal"]?.toString()?.trim() ?? "",
                empresaPorDefecto: x["Empresa por defecto"]?.toString() ?? "",
              });
            });
            this.enableProcess = true;
          } else setTimeout(this.importExcel, 2000);
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
          this.loading = false;
          this.enableProcess = false;
          this.archivoEnProceso = false;
        }
      }
    },
    async saveProcesoImportacion(datosProceso, mostrarResumen) {
      this.loadingProcess = true;
      try {
        const batchSize = 5000;
        const promises = [];

        const request = {
          registros: datosProceso,
          fileName: this.fileName.trim(),
          esTxt: this.isTxt,
          mostrarResumen: mostrarResumen,
        };
        for (let i = 0; i < datosProceso.length; i += batchSize) {
          const batch = datosProceso.slice(i, i + batchSize);
          let batchRequest = { ...request, registros: batch };

          const response = await this.saveProcesoImportacionAfiliadoEmpresa(batchRequest);
          promises.push(response);
        }
        const responses = await Promise.all(promises);
        const allResponses = {
          cantidadBenefEmpresa: responses.flatMap((x) => x.cantidadBenefEmpresa),
          cantidadEmpresas: responses.flatMap((x) => x.cantidadEmpresas),
          procesoFinalizado: responses.flatMap((x) => x.procesoFinalizado),
          cantidadSucursales: responses.flatMap((x) => x.cantidadSucursales),
          registrosErrores: responses.flatMap((x) => x.registrosErrores),
          registrosOk: responses.flatMap((x) => x.registrosOk),
        };
        this.modalExportarExcel = true;
        this.procesoFinalizado = allResponses.procesoFinalizado[allResponses.procesoFinalizado.length - 1];
        if (!this.procesoFinalizado) {
          this.registrosOk = allResponses.registrosOk;
          this.registrosErrores = allResponses.registrosErrores;
          this.registrosToSee = this.registrosOk.concat(this.registrosErrores);
          this.validateDuplicatedItems();
          if (this.registrosErrores.length > 0) this.exportExcelError();
          
        } else {
          this.cantidadEmpresas = allResponses.cantidadEmpresas.reduce((a, b) => a + b, 0);
          this.cantidadBenefEmpresa = allResponses.cantidadBenefEmpresa.reduce((a, b) => a + b, 0);;
          this.cantidadSucursales = allResponses.cantidadSucursales.reduce((a, b) => a + b, 0);;
          this.setAlert({
            type: "success",
            message: "Archivo procesado con éxito.",
          });
        }
      } catch (error) {
        this.setAlert({
          type: "warning",
          message: "Error al procesar archivo. " + error,
        });
      }
      this.loading = false;
      this.loadingProcess = false;
      this.enableProcess = false;
    },
    validateDuplicatedItems() {
      this.registrosToSee.map((item) => {
        if (this.registrosToSee.some((x) =>
              x !== item &&
              x.cuil == item.cuil &&
              x.nroDoc == item.nroDoc &&
              x.tipoDoc == item.tipoDoc &&
              x.cuitEmpresa == item.cuitEmpresa &&
              (x.sucursal == item.sucursal || this.isTxt)))

          item.errores == null ? (item.errores = "Se encontraron afiliados repetidos para la misma empresa. ")
            : (item.errores += "Se encontraron afiliados repetidos para la misma empresa. ");
      });
      this.registrosErrores = this.registrosToSee.filter((x) => x.errores != null);
      this.registrosOk = this.registrosToSee.filter((x) => x.errores == null);
      this.cantRegistrosErrores = this.registrosErrores.length;
      this.cantRegistrosOk = this.registrosOk.length;
    },
    navigateProcesarArchivo() {
      if (this.procesoFinalizado) this.closeModalExcel();
      else {
        if (this.registrosOk.length == 0) this.modalExportarExcel = false;
        else this.saveProcesoImportacion(this.registrosOk, false);
      }
    },
    exportExcelError() {
      let result = [];
      this.registrosErrores.forEach((x) =>
        result.push({
          ["Cuil"]: x.cuil?.toString(),
          ["Nombre afiliado"]: x.nombreAfiliado?.toString(),
          ["Cuit empresa"]: x.cuitEmpresa?.toString(),
          ["Errores"]: x.errores,
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Errores registros");
    },
    getArchivo(rowsToIgnore) {
      // si el archivoEnProceso es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate())
          this.excelData = helpersExcel.excelImport(this.file, rowsToIgnore);
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    readTxtFormatFile() {
      if (this.fileTxt) {
        this.fileDataTranslated = [];
        this.loading = true;
        const reader = new FileReader();
        try {
          if (this.fileTxt.name.toLowerCase().includes(".txt")) {
            this.fileName = this.fileTxt.name;
            reader.onload = (res) => {
              this.content = res.target.result;
              // Dividir y separar las líneas del archivo
              const lines = this.content
                .toString()
                .split("\n")
                .filter((line) => line.trim() !== "");
              lines.forEach((line) => {
                const fields = line.split("|").map((field) => field.trim()); // Separar por "|" y limpiar espacios

                if (
                  fields.length > 0 &&
                  fields[2].trim().length > 0 &&
                  fields[3].trim().length > 0 &&
                  fields[13].trim().length > 0 &&
                  fields[17].trim().length > 0
                ) {
                  this.fileDataTranslated.push({
                    cuil: fields[2], // Columna 3
                    nombreAfiliado: fields[3], // Columna 4
                    cuitEmpresa: fields[13], // Columna 14
                    nombreEmpresa: fields[14], // Columna 15
                    periodo: fields[17], // Columna 18
                  });
                } else {
                  this.fileDataTranslated.push({
                    cuil: fields[2], // Columna 3
                    nombreAfiliado: fields[3], // Columna 4
                    cuitEmpresa: fields[13], // Columna 14
                    nombreEmpresa: fields[14], // Columna 15
                    periodo: fields[17], // Columna 18
                    errores: "Faltan campos requeridos. ",
                  });
                }
              });
            };
            reader.onerror = (err) => console.log(err);
            reader.readAsText(this.fileTxt);
          } else {
            this.fileTxt = null;
            this.loading = false;
            this.enableProcess = false;
            this.$refs["fileTxt"].reset();
            this.setAlert({
              type: "warning",
              message: "Formato de archivo incorrecto.",
            });
          }
        } catch (error) {
          console.log("error", error);
          this.fileTxt = null;
          this.loading = false;
          this.$refs["fileTxt"].reset();
          this.enableProcess = false;
          this.setAlert({
            type: "warning",
            message: "Formato de archivo incorrecto.",
          });
        }
        this.enableProcess = true;
        this.loading = false;
        setTimeout(() => {
          if (this.fileDataTranslated.length === 0) {
            this.enableProcess = false;
            this.fileTxt = null;
            this.loading = false;
            this.$refs["fileTxt"].reset();
            this.setAlert({
              type: "warning",
              message: "No se encontraron datos válidos.",
            });
          }
        }, 100);
      }
    },
    toggleExpandAll() {
      if (this.allExpanded) this.expanded = [];
      else this.expanded = this.registrosToSee.filter(x => x.errores !== null);

      this.allExpanded = !this.allExpanded;
    },
    closeModalExcel() {
      this.$emit("toggleModalProcesoImportacionAfiliadoEmpresa");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso =
        await this.getFormatoProcesoImportacionAfiliadoEmpresa();
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo",
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax",
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false,
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false,
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false,
        },
      ];
      this.showSeeFormatModal = true;
    },
  },
};
</script>

<style scoped>
.invalidConfigText {
  color: red;
}
</style>